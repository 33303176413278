import moment from "moment";
import {
  findFeeOrderCountByItem,
  getOrderDetail,
  orderServiceFeeSignQty
} from "@/views/serviceFeeModule/serviceDetail/api";
import { mapState } from "vuex";

export default {
  name: "",
  components: {

  },
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/serviceFeeModule/serviceFreeList",
          name: "order",
          title: "服务费单据"
        },
        {
          path: "/serviceFeeModule/serviceDetail",
          name: "detail",
          title: "单据详情"
        }
      ],
      orderDetail: {},
      pageLoadFlag: false,
      orderList:[],
      showDisplay:false,
      productArray:[],
      detailInfo:{},
      ordersList:[],
      orderIds:[],
      orderTypeList:[],
      releationOrderList: [], // dms订单关联订单号
      isdMS:false

    };
  },
  computed:{
    totalProd:function (){
      let total = 0
      if(this.productArray&&this.productArray.length>0){
        this.productArray.forEach(item=>{
          total+=item.qty
        })
      }
      return parseInt(total||0)

    },
    totalLoadVolume:function (){
      let total = 0
      if(this.productArray&&this.productArray.length>0){
        this.productArray.forEach(item=>{
          total+=item.qty*Math.max(item.volume,0)
        })
      }
      return total.toFixed(3)
    },
    ...mapState({
      productImgBaseUrl: state =>
        state.user.productImgBaseUrl
          ? state.user.productImgBaseUrl
          : localStorage.getItem("productImgBaseUrl")
    }),
    testList() {
      // 当数据不需要完全显示的时候
      if(this.showDisplay == false){
        let testList = []; // 定义一个空数组
        // 先显示前三个
        if(this.ordersList.length > 1){
          for(var i = 0;i < 1;i++){
            testList.push(this.ordersList[i])
          }
        }else{
          testList = this.ordersList;
        }
        return testList; // 返回当前数组
      }
      else{//展示全部数据
        return this.ordersList;
      }
    },
    testListDMS(){
      // 当数据不需要完全显示的时候
      if(this.showDisplay == false){
        let testListDMS = []; // 定义一个空数组
        // 先显示前三个
        if(this.releationOrderList.length > 1){
          for(var i = 0;i < 1;i++){
            testListDMS.push(this.releationOrderList[i])
          }
        }else{
          testListDMS = this.releationOrderList;
        }
        return testListDMS; // 返回当前数组
      }
      else{//展示全部数据
        return this.releationOrderList;
      }
    }
  },

  mounted() {
    this.id = this.$route.query.id;
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)
    } else {
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
  },
  methods: {
    gotoParentFeeCode(item){
      let routeUrl = this.$router.resolve({
        path: "/serviceFeeModule/serviceDetail",
        query: {
          id:item
        }
      });
      window.open(routeUrl.href, "_blank");
      return
    },
    gotoDmsOrder(item,index){
      let id = item.documentId
      let url = ''
      if(item.documentNum.indexOf('P') > -1) {
        url = '/channelOrder/detail' // 渠道订单
      }else if(item.documentNum.indexOf('S') > -1) {
        if(item.documentType == 'retail') { // 零售订单
          url = '/retailOrder/detailRetail'
        }else if(item.documentType == 'normal') { // 分销采购订单
          url = 'retailOrder/detailFX'
        }
      }
      this.$router.push({
        path: url,
        query: { id: id}
      });
      return
    },
    gotoOrder(item,index){
      if(item.includes('BHO')) {
        this.$router.push({
          path: "/order/detail",
          query: { id: this.orderIds[index] }
        });
        return
      }
    },

    // 查看商品信息差详情
    getDetail(id){
      getOrderDetail({feeCode:id}).then(res=>{
          if (res.data.code == 0)   {
            this.productArray = res.data.data
            this.detailInfo =  res.data.main
            // 关联订单的id
            this.orderIds = res.data && res.data.main.relationOrderId?res.data.main.relationOrderId.split(','):[]
            this.orderTypeList = res.data && res.data.main.relationOrderType?res.data.main.relationOrderType.split(','):[]
            // 关联订单号
            this.ordersList = res.data && res.data.main.relationOrderCode?res.data.main.relationOrderCode.split(','):[]
            if(this.detailInfo.relationOrderCode.indexOf('BHO') > -1) {
              this.isdMS = false
              let itemIds = this.productArray.map(el => el.salesOrderItemId)
              this.getServiceFeeSignQty(itemIds) // 根据订单明细id查询拒收签收取消数量-海信订单
            }else {
              this.isdMS = true
              let itemIds = this.productArray.map(el => el.dmsIdS)
              this.getServiceFeeSignQtyDms(itemIds) // 根据订单明细id查询拒收签收取消数量-渠道、销售
            }
          } else {
            this.$message.warning(res.data.msg)
          }
       })
    },
    // 根据订单明细id查询拒收签收取消数量-海信订单
    getServiceFeeSignQty(itemIds) {
      let data = {
        itemIds: itemIds.join(',')
      }
      orderServiceFeeSignQty(data).then(res=>{
        if(res.data.code == 0 && res.data.list && res.data.list.length > 0) {
          res.data.list.forEach(el => {
            const index = this.productArray.findIndex(it => it.salesOrderItemId == el.itemId)
            if(index > -1){
              this.productArray[index] = {
                ...this.productArray[index],
                ...el,
                rejectQty: el.rejectquantity
              }
              this.$nextTick(()=> {
                this.$set( this.productArray,index,this.productArray[index])

              })
            }
          })
        }
      })
    },
    // 根据订单明细id查询拒收签收取消数量-渠道、销售
    getServiceFeeSignQtyDms(itemIds) {
      let data = {
        itemsIds:itemIds.join(',')
      }
      findFeeOrderCountByItem(data).then(res=>{
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        if(data.code == 0 && data && data.data.length > 0) {
          const list = []
          data.data.forEach(el => {
            const index =  this.productArray.findIndex(it => it.dmsIdS == el.dmsIdS)
            const orderIndex = list.findIndex(it => it.documentNum == el.documentNum)
            if(index > -1){
              this.productArray[index] = {
                ...this.productArray[index],
                ...el
              }
            }
            if(orderIndex == -1) {
              list.push(el)
            }
            this.$nextTick(()=> {
              this.$set( this.productArray,index,this.productArray[index])
            })
          })
          this.releationOrderList = list
        }else {
          this.releationOrderList = []
        }
      })
    },

    formatPrice(price) {
      return (Math.round(new Number(price) * 1000) / 1000).toFixed(2)
    },
    getPrice(item) {

    },
    // 查看发票
    goXY(url){
      window.location.href= url
    },
    //查看协议
    gotoXY(){
      this.$router.push({
        path: "/serviceFeeModule/logisicsAgreent",
      });
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    goBack() {
      this.$router.go(-1);
    },


  }
};