import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";


//获取订单详情
export function getOrderDetail(data) {
  return http({
    method: "post",
    url: "/orderServiceFee/queryFeeItem.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
//获取支付详情
export function selectByCode(data) {
  return http({
    method: "get",
    url: "/orderServiceFee/selectByCode.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}
// 在线支付接口
export function payInfo(data) {
  return http({
    method: "post",
    url: "/orderServiceFee/payInfo.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
// sap支付
export function sapPay(data) {
  return http({
    method: "post",
    url: "/orderServiceFee/sapPay.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}

// 获取订单的拒收数量取消数量 cis
export function orderServiceFeeSignQty(data) {
  return http({
    method: "GET",
    url: "/orderServiceFee/orderServiceFeeSignQty.nd" ,
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}
// 获取订单的拒收数量取消数量 dms
export function findFeeOrderCountByItem(data) {
  return dmshttp({
    url: 'findFeeOrderCountByItem',
    data
  })
}